<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">

        <b-card>
          <template #header>
            <i class="bx bxs-contact"></i>
            {{ $t("shipments.consignee") }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>

          <form-input
            type="select"
            label="common.locale" 
            rules="required" 
            icon="bx-message-square-detail"
            :options="locales"
            :clearable="false"
            v-model="item.locale"
          />
          
          <PhoneInput required label="common.phone" v-model="item" />

          <form-input
            type="email"
            label="common.email" 
            icon="bx-envelope"
            rules="email"
            v-model="item.email"
          />
        </b-card>

      </div>
      <div class="col mb-4">

        <b-card>
          <template #header>
            <i class="bx bxs-truck"></i>
            {{ $t("shipments.label") }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input 
                label="shipments.type" 
                icon="bx-timer"
                type="select"
                v-model="item.type" 
                rules="required"
                :reduce="opt => opt.code" 
                :options="resource.types | transMap" 
                :clearable="false" 
              />
            </div>
            <div class="col">
              <form-input 
                label="shipments.external-reference" 
                icon="bxs-purchase-tag"
                rules="required"
                v-model="item.external_reference_id"
              />
            </div>
          </div>

          <template v-if="features & FEATURE_CONTENT">
            <form-input 
              label="shipments.contents" 
              icon="bx-detail"
              type="select"
              v-model="item.contents" 
              :reduce="c => c" 
              :options="preferences.content_types || []" 
              :clearable="true" 
              :placeholder="$t('packages.singular')"
              :description="$t('shipments.contents-manage-desc')"
            />
          </template>


          <form-input
            type="item" 
            label="shipments.pickup-location" 
            icon="bx-map" 
            v-model="item.pickup_location_id"
            resource-name="locations"
            rules="required"
            :disabled="!item.merchant_id"
            :refreshOn="item.merchant_id"
            :scope="['pickup','active','approved',['merchant', item.merchant_id]]"
            :clearable="false"
          />
          <form-input
            type="item" 
            label="shipments.dropoff-location" 
            icon="bx-map" 
            v-model="item.dropoff_location_id"
            resource-name="locations"
            :disabled="!item.merchant_id"
            :disabled-value="{code: item.dropoff_location_id, label: item.dropoff_location_name}"
            :description="containsOversized ? $t('shipments.dropoff-pudo-only') : undefined"
            :refreshOn="item.merchant_id"
            :scope="dropoffScope"
            :clearable="true"
            :metaOptions="[{
              code: 'request',
              label: $t('shipments.request-dropoff-option')
            }]"
          />
        </b-card>

        <b-card no-body v-if="creating">
          <template #header> 
            <i class='bx bxs-package' />
            {{$t('packages.title')}}
          </template>

          <div class="container my-3">
            <div class="row py-1" v-for="{code: size,label} in sizes" :key="size">
              <div class="col" :class="{'text-muted': size === 'OVERSIZED'}">
                {{$t(label)}} <br>
                <span class="small text-muted">{{$t(`packages.dimensions.${size}`)}}</span>
              </div>
              <div class="col">
                <b-form-spinbutton 
                  :disabled="size === 'OVERSIZED'"
                  class="float-right"
                  :style="{maxWidth: '10rem'}" 
                  v-model="item[`packages_${size.toLowerCase()}`]" 
                  min="0" 
                  max="100"
                ></b-form-spinbutton>
              </div>
            </div>
          </div>

        </b-card>

        <merchant-card v-if="userIsSuperAdmin" :item="item" />
      </div>
    </div>

    <template #buttons="{invalid,touched}" v-if="creating">
      <b-button :disabled="invalid || !printable" @click="saveAndPrint" :variant="printable ? 'primary' : null" class="float-right ml-2">
        {{ $t("shipments.create-and-print") }}
      </b-button>
      <b-button :disabled="invalid" @click="save" :variant="printable ? null : 'primary'" class="float-right">
        {{ $t("shipments.create-as-draft") }}
      </b-button>
      <span v-if="touched && invalid" class="p-2 text-danger float-right">{{$t('validation.form-invalid')}}</span>
      <b-button @click="cancel" variant="light">
        {{ $t("item.cancel") }}
      </b-button>
    </template>

  </ItemEditTemplate>
</template>

<script>
import ItemEdit from '@/components/ItemEdit'
import ItemEditTemplate from '@/components/ItemEditTemplate'
import FormInput from "@/components/Form/Input";
import MerchantCard from "@/components/Form/MerchantCard";
import Package, { SIZE_OVERSIZED } from '@/resources/Package';
import { CLASS_PUDO } from '@/resources/Location';
import PhoneInput from '@/components/Form/PhoneInput';
import { FEATURE_CONTENT } from '@/resources/Merchant';

export default {
  extends: ItemEdit,
  components: { 
    ItemEditTemplate, 
    FormInput, 
    MerchantCard,
    PhoneInput
  },
  data() {
    return {
      sizes: Package.sizes,
      specifyContentsFlag: false,
      FEATURE_CONTENT      
    }
  },
  computed: {
    containsOversized() {
      return this.item.$meta.packages?.some(p => p.size == SIZE_OVERSIZED)
    },
    dropoffScope() {
      let scopes = [
        ['dropoff',this.item.merchant_id],
        'active'
      ]

      if(this.containsOversized) {
        scopes.push(['class',CLASS_PUDO])
      }

      return scopes
    },
    packageCount() {
      if(this.creating) {
        return this.sizes.reduce((carry,{code: size}) => {
          return carry += this.item[`packages_${size.toLowerCase()}`]
        },0)
      } else {
        return this.item.package_count
      }
    },
    printable() {
      return this.packageCount && Number.isInteger(this.item.dropoff_location_id)
    },
    locales() {
      return this.$i18n.availableLocales.reduce((c,v) => (c.push({code:v,label:`locale.${v}`}),c),[])
    }
  },
  created() {
    if(this.creating) {
      Package.sizes.map(s => s.code).forEach(code => {
        this.$set(this.item,`packages_${code.toLowerCase()}`,0)
      })
    }
  },
  methods: {
    async saveAndPrint() {
      let alsoRequestPickup = await this.$bvModal.msgBoxConfirm(this.$t('shipments.create-also-request-pickup'),{
        autoFocusButton: 'ok',
        cancelTitle: this.$t('common.no'),
        okTitle: this.$t('common.yes'),
      })

      this.loading = true

      let saved = false

      try {
        saved = await this.item.$save()
        await this.item.$register()
        await this.item.$print()

        if(alsoRequestPickup) {
          await this.item.$requestPickup()
        }

      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.guardChanges = false
        saved && this.afterSave()
      }
    },
  }
};
</script>